<template>
    <div class="row">
        <div class="col-12 col-lg-10 c g">
            <div class="card card-body">
                <div class="col-12 g text-center" v-if="user.worker != '5'">
                    <div class="alert alert-danger">
                        <h2>
                            برجاء التواصل مع الدعم الفني لتفعيل الخدمة لك.
                        </h2>
                    </div>
                </div>
                <div class="col-12 g">
                    <button class="btn btn-primary" v-b-modal.modal-1>
                        <i class="fa fa-plus"></i>
                        اضافة رد جديد
                    </button>
                    &nbsp;
                    <button class="btn btn-sm btn-primary" v-if="c == 'stop' && replies.length" @click="c = 'start'; changeStatus()">
                        <i class="fa fa-check">&nbsp; تفعيل الردود التلقائية</i>
                    </button>
                    <button class="btn btn-sm btn-danger" v-if="c == 'start' && replies.length" @click="c = 'stop'; changeStatus()">
                        <i class="fa fa-times">&nbsp; ايقاف الردود التلقائية</i>
                    </button>
                </div>
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                الرسالة
                            </th>
                            <th>
                                الرد
                            </th>
                            <th>
                               النوع 
                            </th>
                            <th>
                               التصحيح الإملائي
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in replies" :key="u._id">
                                <td>
                                    {{ u.message }}
                                </td>
                                <td>
                                    <textarea class="form-control" style="min-width: 200px" rows="3" readonly v-model="u.reply"></textarea>
                                </td>
                                <td>
                                    <span v-if="u.type == 'same'">اذا كانت الرسالة مطابقة لنص معين</span>
                                    <span v-if="u.type == 'contains'">اذا كانت الرسالة تحتوي على نص معين</span>
                                    <span v-if="u.type == 'firsttime'">عند استقبال اول رسالة فقط</span>
                                    <span v-if="u.type == 'everyday'">عند استقبال اول رسالة في لكل يوم</span>
                                </td>
                                <td>
                                    {{ u.correct ? 'نعم' : 'لا' }}
                                </td>
                                <td>
                                    <span class="g">
                                        <button class="btn btn-danger btn-sm fa fa-trash" @click="deleteMe(u._id)"></button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    <b-modal
      id="modal-1"
      title="اضافة رد تلقائي"
      ok-only
      hide-footer
    >
        <p>اختر نوع الرد:</p>
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="type" value="same">
                <h5>
                    اذا كانت الرسالة مطابقة لنص معين
                </h5>
          </label>
        </div>
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="type" value="contains">
                <h5>
                    اذا كانت الرسالة تحتوي على نص معين
                </h5>
          </label>
        </div>
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="type" value="firsttime">
                <h5>
                    عند استقبال اول رسالة فقط
                </h5>
          </label>
        </div>
        <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="type" value="everyday">
                <h5>
                    عند استقبال اول رسالة في لكل يوم
                </h5>
          </label>
        </div>
        <hr>
        <div class="form-group" v-if="type == 'same' || type == 'contains'">
          <h5 for="">النص (من الرسائل الواردة)</h5>
          <input type="text"
            class="form-control" v-model="message" placeholder="مثال: السلام عليكم">
        </div>
        <div class="form-check" v-if="type == 'same'">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="correct" :value="true">
            تمكين التصحيح الإملائي (<span class="text-muted">مثال: اذا ارسل العميل "السلام <u>عليكن</u>" النظام سوف يفهمها "السلام عليكم"</span>)
          </label>
        </div>
        <hr>
        <div class="form-group">
          <h5 for="">الرد</h5>
          <textarea class="form-control" v-model="reply" rows="3" placeholder="مثال: وعليكم السلام ورحمة الله وبركاته"></textarea>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addNow()">
                اضافة الرد الآن
            </button>
        </div>
    </b-modal>
    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            replies: [],
            message: "",
            reply: "",
            type: "same",
            correct: null,
            current: {},
            c: "start"
        }
    },
    created(){
        var g = this;
        if(g.user.plan != 3){
            alert("عفواً هذه الخدمة متاحة في الخطة الذهبية فقط.")
            g.$router.push('/')
            return;
        }
        $.post(api + "/user/messages/replies/list", {
        jwt: this.user.jwt,
        })
        .then(function (r) {
            g.replies = r.response;
            if(g.replies[0]){
                if(g.replies[0].activated){
                    g.c = "start"
                }else{
                    g.c = "stop"
                }
            }
        })
        .catch(function () {
            g.loading = false;
            g.$toast({
            component: ToastificationContent,
            props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "EditIcon",
                variant: "danger",
            },
            });``
        });
    },
    methods: {
        addNow(){
            var g = this;
            $.post(api + "/user/messages/replies/add", {
                jwt: this.user.jwt,
                message: this.message,
                reply: this.reply,
                correct: this.correct,
                type: this.type
            })
            .then(function (r) {
                alert("ستيم تطبيق الرد الجديد خلال دقيقة من الآن...")
                location.reload()
            })
            .catch(function () {
                g.loading = false;
                g.$toast({
                component: ToastificationContent,
                props: {
                    title: "حدثت مشكلة في الاتصال",
                    icon: "EditIcon",
                    variant: "danger",
                },
                });
            });
        },
        changeStatus(){
            var g = this;
            if(!g.c){
                g.c = "stop"
            }
            $.post(api + "/user/messages/replies/" + g.c, {
                jwt: this.user.jwt
            })
            .then(function (r) {
                location.reload()
            })
        },
        deleteMe(id){
            var g = this;
            if(confirm("متاكد من الحذف؟")){
                $.post(api + "/user/messages/replies/delete", {
                    jwt: this.user.jwt,
                    id: id
                })
                .then(function (r) {
                    location.reload()
                })
                .catch(function () {
                    g.loading = false;
                    g.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "حدثت مشكلة في الاتصال",
                        icon: "EditIcon",
                        variant: "danger",
                    },
                    });
                });
            }
        }
    }
}
</script>

<style scoped>
.table tr td{
    white-space: nowrap !important
}
</style>